import Vue from 'vue';

import { reactive } from 'vue';
interface BusEvents {
    [key: string]: BusCallback[];
}

interface BusCallback {
    (...args: any[]): void;
}

export const bus = reactive({
    events: {} as BusEvents,
    $on(event: string, callback: BusCallback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    },
    $off(event: string, callback: BusCallback) {
        if (!callback) {
            this.events[event] = [];
            return;
        }
        this.events[event] = this.events[event].filter(cb => cb !== callback);
    },
    $emit(event: string, ...args: any[]) {
        if (!this.events[event]) {
            return;
        }
        this.events[event].forEach(cb => cb(...args));
    }
});