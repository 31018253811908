
import { IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonPage, IonIcon, IonContent, IonModal, IonItem, IonLabel, IonInput, IonButton, IonButtons, IonTitle, IonToolbar, IonHeader, IonList } from '@ionic/vue';
import { moon, personCircle, gridSharp, locateSharp, calendarOutline, personCircleOutline, apertureOutline, homeOutline, settingsOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { mapGetters, mapActions } from "vuex";
import {OverlayEventDetail} from "@ionic/core/components";


export default defineComponent({
  name: 'KRAFTAPP',
  components: {
    IonPage,
    IonIcon,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet,
    IonContent,
    IonModal,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonList
  },
  data() {
    return {
      userInfo: {
        email: "",
        password: "",
      },
      message: 'Was geht ab.',
    };
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
  },
  methods:{
    ...mapActions("auth", {
      loginUser: "loginUser",
    }),

    async themeToggle() {
      document.body.classList.toggle('dark');
    },
  },
  setup() {
    return {moon, personCircle, locateSharp, calendarOutline, personCircleOutline, apertureOutline, gridSharp, homeOutline, settingsOutline};
  },
});
