import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!

  return (_openBlock(), _createBlock(_component_ion_toggle, {
    mode: "ios",
    color: "dark",
    checked: !_ctx.mode,
    onIonChange: _ctx.ionToggleChange,
    "aria-label": "DarkMode"
  }, null, 8, ["checked", "onIonChange"]))
}