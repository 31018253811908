import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import store from "../store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/tabs/tab1',
  },
  {
    path: '/questionnaire',
    component: () => import('@/views/Questionnaire.vue')
  },
  {
    path: '/tabs/',
    component: HomePage,
    children: [
      {
        path: '',
        redirect: 'tab1',
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3Page.vue')
      },
      {
        path: 'tab4',
        component: () => import('@/views/Tab4Page.vue'),
        meta: { requiredAuth: true },
      }
    ]
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/FolderPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
/*
function guard(to: any, from: any, next: any, authData: any) {
  if (to.meta && to.meta.requiredAuth) {
    if (authData && authData.userId > 0) {
      return next();
    }
    return next({ path: "/login" });
  } else {
    if (authData && authData.userId > 0) {
      return next({ path: "/tabs/tab1" });
    }
    return next();
  }
}

router.beforeEach((to, from, next) => {
  let authData = store.getters["auth/getAuthData"];
  if (authData.userId == 0) {
    store.dispatch("auth/loadStorageTokens").then(
        () => {
          authData = store.getters["auth/getAuthData"];
          return guard(to, from, next, authData);
        },
        (error) => {
          console.log(error);
          return guard(to, from, next, authData);
        }
    );
  } else {
    return guard(to, from, next, authData);
  }
});
*/


export default router
