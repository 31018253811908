
import { IonToggle } from '@ionic/vue';
import { defineComponent } from 'vue';
import { bus } from '@/shared/bus';

export default defineComponent({
  name: 'DarkModeToggleButton',
  props: {
  },
  data() {
    return {
      mode: false,
    };
  },
  components: {
    IonToggle
  },
  methods:{
   async themeToggle(darkmode: boolean) {
     this.mode = darkmode;
     if(darkmode)
       document.body.classList.add('dark');
     else
       document.body.classList.remove('dark');
   },
   ionToggleChange(event: any){
     bus.$emit('darkmode', !event.detail.checked);
   }
  },
  created() {
    bus.$on('darkmode', (mode: any) => {
      this.themeToggle(mode);
    });
  }

});
